<template>
  <section class="main-wrap">
    <header v-if="clientId" class="inner-page-header">
      <div class="header-title-wrap">
        <router-link to="/clients" class="client-profil-wrap">
          <div class="overlay-back">
            <svg width="7" height="12" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M5.768 12c.317 0 .621-.126.864-.366.475-.492.475-1.301 0-1.793L2.945 6l3.7-3.839c.474-.492.474-1.3 0-1.793a1.195 1.195 0 0 0-1.729 0L.353 5.105C.122 5.345 0 5.66 0 6c0 .341.134.657.353.897l4.551 4.736c.244.24.56.366.864.366z"
                fill="#fff"
              />
            </svg>
          </div>
          <client-logo v-if="selectedClient" :client="selectedClient" class-name="is-medium"></client-logo>
        </router-link>

        <div v-if="selectedClient" class="header-title">
          <h1 class="big-title" @click.prevent="enterLiveEditMode" @blur="saveLiveEdit">
            {{ selectedClient.name }}
          </h1>
          <h2 class="small-title">Projects</h2>
        </div>
      </div>

      <div class="action-wrap">
        <router-link :to="{ name: 'CreateCampaign', params: { clientId: clientId } }">
          <div class="bt smaller">
            <p>Create a Project</p>
            <img alt="Plus" class="bt-img" src="@/assets/img/plus-icon.svg" />
          </div>
        </router-link>

        <!-- <router-link :to="{ name: 'CreateClient', params: { clientId: clientId } }">
          <div class="settings-wrap">
            <div class="v-line"></div>
            <div class="v-line"></div>
            <div class="v-line"></div>
            <div class="bullet left"></div>
            <div class="bullet center"></div>
            <div class="bullet right"></div>
          </div>
        </router-link> -->
      </div>
    </header>

    <div v-if="clientId" class="campaigns-wrap">
      <router-link
        v-for="(campaign, index) in campaigns"
        :key="index"
        class="campaign toggle-options"
        :to="{
          name: 'Campaign',
          params: { clientId: clientId, campaignId: campaign.id },
        }"
      >
        <div class="infos">
          <ContextualToggleEdit
            :client="campaign"
            :actions="['edit', 'duplicate', 'archive', 'delete']"
            :edit-action="editCampaign"
            :duplicate-action="duplicateCampaign"
            :archive-action="archiveCampaign"
            :delete-action="deleteCampaign"
          ></ContextualToggleEdit>
          <p class="campaing-name">{{ campaign.name }}</p>
          <p class="campaing-ads">
            <span v-if="campaign.platforms.includes('facebook')">
              {{ campaign.nbAdz ? campaign.nbAdz : '0' }} Facebook ad{{ campaign.nbAdz && campaign.nbAdz > 1 ? 's' : '' }}
            </span>
            <span v-if="campaign.platforms.includes('googleAds') & campaign.platforms.includes('facebook')"> | </span>
            <span v-if="campaign.platforms.includes('googleAds')">
              {{ campaign.nbGoogleAdz ? campaign.nbGoogleAdz : '0' }} Google ads campaign{{ campaign.nbGoogleAdz > 1 ? 's' : '' }}
            </span>
          </p>
        </div>
        <div class="platform">
          <img
            v-for="(platform, indexB) in platforms"
            :key="indexB"
            :alt="platform.name"
            :src="platform.icon"
            :class="{ 'is-active': campaign.platforms.includes(platform.id) }"
          />
        </div>
        <div class="hover"></div>
      </router-link>
    </div>

    <div v-if="!campaigns || (campaigns && !campaigns.length)" class="no-data-wrap" :class="{ 'no-data': campaigns && !campaigns.length }">
      <img alt="Showadz" src="@/assets/img/no-data.svg" />
      <h2 class="big-title">Create your first campaign</h2>
      <router-link :to="{ name: 'CreateCampaign', params: { clientId: clientId } }">
        <div class="bt">
          <p>Create a campaign</p>
          <img alt="Plus" class="bt-img" src="@/assets/img/plus-icon.svg" />
        </div>
      </router-link>
    </div>
  </section>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import { isNil } from 'lodash'
import ContextualToggleEdit from '@/components/actions/ContextualToggleEdit'

import AccountclientsDB from '@/firebase/account-clients-db'
import AccountClientCampaignsDB from '@/firebase/account-client-campaigns-db'

import ClientLogo from '@/components/clients/ClientLogo'

import { doAlert } from '@/misc/helpers'

export default {
  components: {
    ContextualToggleEdit,
    ClientLogo,
  },

  head: function () {
    return {
      title: {
        inner: 'Projects list',
      },
      meta: [
        {
          name: 'description',
          content: `${this.appTitle} campaings projects list`,
          id: 'desc',
        },
      ],
    }
  },

  data() {
    return {
      campaigns: null,
      currentClient: null,
    }
  },

  computed: {
    clientId() {
      return this.$route.params.clientId
    },
    ...mapState('app', ['appTitle', 'platforms']),
    ...mapState('users', ['account', 'selectedClient']),
  },

  watch: {
    account: {
      async handler(account) {
        if (!isNil(account)) {
          // console.log('watch()', account)
          this.fetchCampaigns(account)

          // Select Client
          const accountClientsRef = new AccountclientsDB(this.account[0].id, this.$route.params.clientId)
          const fetchClient = await accountClientsRef.read(this.$route.params.clientId)

          this.currentClient = fetchClient
          this.selectClient(this.$route.params.clientId)
        }
      },
      immediate: true,
    },
  },

  mounted() {
    this.selectCampaign({
      clientId: '',
      campaignId: null,
    })
  },

  methods: {
    async editCampaign(campaign) {
      const route = {
        name: 'EditCampaign',
        params: {
          clientId: this.$route.params.clientId,
          campaignId: campaign.id,
        },
      }
      this.$router.push(route)
    },

    async duplicateCampaign(campaign) {
      this.$swal({
        title: 'Duplicate',
        text: `Are you sure you want to duplicate ${campaign.name}?`,
        type: 'warning',
        showCancelButton: true,
        showCloseButton: true,
        imageUrl: '/img/icons/duplicate.svg',
        confirmButtonColor: '#4628FF',
        cancelButtonColor: '#637082',
        confirmButtonText: 'Yes',
        cancelButtonText: 'No',
      }).then((result) => {
        if (result.value) {
          const accountClientCampaignsDbRef = new AccountClientCampaignsDB(this.account[0].id, this.$route.params.clientId)
          const newCampaign = { ...campaign }
          newCampaign.name = `${newCampaign.name} (copy)`
          delete newCampaign.id
          accountClientCampaignsDbRef.create(newCampaign)
          doAlert({
            title: `${campaign.name} duplicated`,
            type: 'success',
          })

          this.fetchCampaigns(this.account)
        }
      })
    },

    async archiveCampaign(campaign) {
      this.$swal({
        title: 'Archive',
        text: `Are you sure you want to archive ${campaign.name}?`,
        type: 'warning',
        showCloseButton: true,
        showCancelButton: true,
        imageUrl: '/img/icons/archive.svg',
        confirmButtonColor: '#4628FF',
        cancelButtonColor: '#637082',
        confirmButtonText: 'Yes',
        cancelButtonText: 'No',
      }).then((result) => {
        if (result.value) {
          const accountClientCampaignsDbRef = new AccountClientCampaignsDB(this.account[0].id, this.$route.params.clientId)
          const newCampaign = { ...campaign, isArchived: true }

          accountClientCampaignsDbRef.update(newCampaign)

          doAlert({ title: `${campaign.name} archived`, type: 'success' })

          this.fetchCampaigns(this.account)
        }
      })
    },

    async deleteCampaign(campaign) {
      this.$swal({
        title: 'Delete',
        text: `Are you sure you want to delete ${campaign.name}?`,
        type: 'warning',
        showCloseButton: true,
        showCancelButton: true,
        imageUrl: '/img/icons/delete.svg',
        confirmButtonColor: '#4628FF',
        cancelButtonColor: '#637082',
        confirmButtonText: 'Yes',
        cancelButtonText: 'No',
      }).then(async (result) => {
        if (result.value) {
          const accountClientCampaignsDbRef = new AccountClientCampaignsDB(this.account[0].id, this.$route.params.clientId)
          await accountClientCampaignsDbRef.delete(campaign.id)
          doAlert({ title: `${campaign.name} deleted`, type: 'success' })

          this.fetchCampaigns(this.account)
        }
      })
    },

    async saveLiveEdit(e) {
      // console.log('Closing and Saving liveEdit()')

      const target = e.target

      // console.log(target.getAttribute('contenteditable'))

      if (target.getAttribute('contenteditable')) {
        // console.log('Yeah!')

        const accountClientsDbRef = new AccountclientsDB(this.account[0].id, this.$route.params.clientId)

        const updateClient = { ...this.currentClient, name: target.textContent.trim() }

        await accountClientsDbRef.update(updateClient)

        target.removeAttribute('contenteditable')

        // Refresh State
        this.selectClient(this.$route.params.clientId)
      }
    },

    async fetchCampaigns(account) {
      const accountClientCampaignsDbRef = new AccountClientCampaignsDB(account[0].id, this.$route.params.clientId)
      this.campaigns = await accountClientCampaignsDbRef.readAll(null, false)
    },

    ...mapActions('app', ['enterLiveEditMode']),
    ...mapActions('users', ['getUserClientCampaigns', 'selectClient', 'selectCampaign']),
  },
}
</script>

<style lang="scss" scoped>
@import '@/theme/variables.scss';
@import '@/theme/typography.scss';
@import '@/theme/button.scss';
@import '@/theme/nodata.scss';
@import '@/theme/page-title.scss';

.campaign {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  background-color: #fff;
  border: 1px solid #e0e2e6;
  border-radius: 3px;
  padding: 25px 35px;
  margin-bottom: 20px;
  transition: all 0.5s ease;

  &:hover {
    transform: scale(1.02);

    .hover {
      left: -5px;
      opacity: 1;
    }

    .platform {
      position: absolute;
      right: 15px;
      bottom: 25px;
      img {
        opacity: 0.3;
        height: 20px;
        top: 5px;
        margin-left: 2vw;
      }
    }
  }

  .hover {
    position: absolute;
    left: 0px;
    top: 0;
    width: 10px;
    height: 100%;
    opacity: 0;
    border-top-left-radius: 3px;
    border-bottom-left-radius: 3px;
    background: linear-gradient(to bottom, #2e7df2, #8a23f1);
    transition: all 0.2s ease;
  }

  .infos {
    color: $dark-grey;
    font-weight: 600;

    .campaing-name {
      font-size: 16px;
    }

    .campaing-ads {
      font-size: 14px;
      opacity: 0.5;
    }
  }

  .platform {
    display: flex;
    justify-content: space-around;
    align-items: baseline;

    img {
      position: relative;
      top: 0px;
      opacity: 1;
      display: none;
      width: auto;
      height: 25px;
      margin-left: 2.5vw;
      transition: all 0.5s ease;

      &.is-active {
        display: inline-block;
      }
    }
  }
}
</style>
