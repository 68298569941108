<template>
  <img v-if="client.croppedImg" alt="Client" :class="className" :src="client.croppedImg" />
  <img v-else-if="client.profileImg" alt="Client" :class="className" :src="client.profileImg" />
  <span v-else :class="['empty-image-letter', className]">{{ client.name[0].toUpperCase() }}</span>
</template>

<script>
export default {
  props: {
    client: {
      type: Object,
      required: true,
    },
    className: {
      type: String,
      required: false,
    },
  },
}
</script>

<style lang="scss" scoped>
img {
  display: inline-block;
}

img.is-medium {
  display: inline-block;
  width: 55px;
  height: 55px;
  border-radius: 50%;
}

.empty-image-letter {
  display: inline-block;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  text-align: center;
  background: rgba(0, 0, 0, 0.08);
  line-height: 40px;
  margin-right: 0;
  color: rgba(0, 0, 0, 0.58);
  font-weight: 800;

  &.is-medium {
    width: 55px;
    height: 55px;
    line-height: 55px;
    margin-right: 0;
  }

  &.is-big {
    width: 100px;
    height: 100px;
    line-height: 100px;
    background: transparent;
  }
}
</style>
